import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import {SectionWrap} from './press-area.style'
import Text from '../../../components/ui/text'

const ServicesArea = (props) => {
    const featuredDataQuery = useStaticQuery(graphql `
        query PressQueryData {
          indexInfotechnoJson(id: {eq: "infotechno-press-content"}) {
            title
            items {
              title
              link
              image {
                childImageSharp {
                  fluid(base64Width: 470, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
               }
              }
            }
        }
    `); 
    const featureData = featuredDataQuery.indexInfotechnoJson
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            title={featureData.title}
                        />
                    </Col>
                </Row>
                <Row>
                    {featureData.items && featureData.items.map(feature => {
                      return (
                          <Col lg={3} md={6} className="box-item" key={feature.title}>
                            <a style={{width: '100%'}} href={feature.link} target="_blank">
                              <img style={{width: '100%', height: '300px', objectFit: 'cover'}} src={feature.image.childImageSharp.fluid.src} />
                              <Text fontSize="20px" lineHeight="1" mt="10px">{feature.title}</Text>
                            </a>
                          </Col>
                        )
                    })}
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default ServicesArea;