import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../containers/about-us/page-header'
import AboutArea from '../containers/about-us/about-area'
import VideoArea from '../containers/index-infotechno/video-area'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import ContactArea from '../containers/global/contact-area/contact-four'
import ServicesArea from '../containers/it-services/press-area'
import MailchimSignup from '../components/mailchim-signup'

const AboutPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="About Us" />
    <Header />
    <main className="site-wrapper-reveal">
      <PageHeader />
      <AboutArea />
      <ServicesArea/>
      <VideoArea />
      <TestimonialArea />
      <ContactArea />
    </main>
    <MailchimSignup />
    <Footer />
  </Layout>
)

export default AboutPage
