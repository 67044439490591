import styled, {css} from 'styled-components'
import {device} from '../../../theme'
import BackgroundImage from 'gatsby-background-image'

export const PageHeaderWrap = styled(BackgroundImage) `
    padding-top: 192px;
    padding-bottom: 165px;
    @media ${device.medium}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    position: relative;
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    div:first-child {
      position: relative;
      z-index: 10;
    }
`;

export const Overlay = styled.div `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${css `
    background-color: ${props => props.theme.colors.textColor};
    `}
    opacity: 0.5;
`;
